export const Spinner = () => {
    return <svg className="spinner-chasing" width="32" height="32" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
    </svg>
}

export const SpinnerMini16 = () => {
    return <svg className="spinner-chasing" width="16" height="16" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
    </svg>
}