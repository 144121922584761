import { StrictMode } from 'react';
import { Menu, MenuItem, Sidebar, sidebarClasses } from "react-pro-sidebar";
import * as reactRouterDom from "react-router-dom";
import { Outlet } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { redirectToAuth } from "supertokens-auth-react";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Banner } from "./Banner";
import { BaseInformation } from "./BaseInformation";
import { SideBarProjectMenu } from "./projects/SideBarProjectMenu";
// import { useColorTheme } from "../utils/color-theme";
import {
    useGetUserCurrentOrganizationQuery,
} from "../redux/MosaicApi/organizationReducer";

export const rootLoader = async () => {
    if (await Session.doesSessionExist()) {
        return reactRouterDom.defer({
        });
    } else {
        redirectToAuth();
        return null;        
    }
};

export const Root = () => {
    const navigate = useNavigate();

    const {
        currentData,
        data: organization = {},
    } = useGetUserCurrentOrganizationQuery("root");

    const openDocs = () => {
        window.open("https://featrix-docs.readthedocs.io/en/latest/", "_blank");
    };

    const openBilling = () => {
        navigate(`/organizations/${organization._id}/billing`);
    };

    const openAPIKeys = () => {
        navigate(`/organizations/${organization._id}/apikeys`);
    };

    const openWWW = () => {
        window.open("https://www.featrix.ai/", "_blank");
    };

    const openMeeting = () => {
        window.open("https://bits.featrix.com/meet/?ui-bottom", "_blank");
    };

    //  setHasProjects(loaderData.hasProjects)
    return (
        <>
            <Banner />
            <SessionAuth>
                {/* <ErrorBoundary> */}

                <BaseInformation>
                    <StrictMode>
                        <div
                            className="below-header grid"
                            style={{
                                top: "80px",
                                position: "absolute",
                                width: "200px"
                            }}>
                            <Sidebar
                                width="200"
                                id="main-sidebar-menu"
                                rootStyles={{
                                    [`.${sidebarClasses.container}`]: {
                                        paddingLeft: "0px",
                                        paddingTop: "16px",
                                        paddingBottom: "0px",
                                        color: "var(--featrix-headline-text-color)",
                                        background: "linear-gradient(294.17deg, #303030 35.57%, #1d1d22 92.42%, #454545)",
                                        //"var(--featrix-side-menu-bg-color)",
                                    },
                                }}
                            >
                                <Menu className="project-menu" width="200">
                                    <SideBarProjectMenu />
                                </Menu>
                                <Menu className="bottom-menu" style={{ paddingBottom: "16px" }}>
                                    <MenuItem onClick={() => openMeeting()}>Get Live Help</MenuItem>
                                    <MenuItem onClick={() => openAPIKeys()}>API Keys</MenuItem>
                                    <MenuItem onClick={() => openBilling()}>Billing</MenuItem>
                                    <MenuItem onClick={() => openDocs()}>Documentation</MenuItem>
                                    <MenuItem onClick={() => openWWW()}>www.featrix.ai</MenuItem>
                                </Menu>
                            </Sidebar>
                        </div>
                        <div id="background">
                            <main className="ml-24">
                                <Outlet />
                            </main>
                        </div>
                    </StrictMode>
                </BaseInformation>
                {/* </ErrorBoundary> */}
            </SessionAuth>
        </>
    );
};
