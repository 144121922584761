import { neuralApi } from "./neuralApiReducer";
import moment from "moment/moment";

let sinceLastEmbeddingsRequest;

export const embeddingReducer = neuralApi.injectEndpoints({
    endpoints: (build) => ({
        getAllEmbeddingSpaces: build.query({
            queryFn: async (arg, queryApi, extraOptions, fetchWithBQ) => {
                // Use the global variable in request

                // dbg("queryFn");
                // dbg(arg);
                let url = `/embedding_space/${arg || ""}`;
                if (arg === undefined) {
                    if(sinceLastEmbeddingsRequest !== undefined)
                    {
                        // dbg(`Using sinceLastEmbeddingsRequest ${sinceLastEmbeddingsRequest}`);
                        url += `?since=${sinceLastEmbeddingsRequest}`
                    }
                }

                const result = await fetchWithBQ({
                    url: url,
                });
                if (!result.error) {
                    // dbg("setting sinceLastEmbeddingsRequest ")
                    result.data.forEach(item => {
                        if(!sinceLastEmbeddingsRequest || moment.utc(item.updated_at) > moment.utc(sinceLastEmbeddingsRequest)) {
                            // dbg(`Setting sinceLastEmbeddingsRequest to ${item.updated_at} becaause of ${item._id}`);
                            sinceLastEmbeddingsRequest = moment.utc(item.updated_at).toISOString();
                        }
                    })
                }
                // dbg("Results");
                // dbg(result);
                // Handle the response data update accordingly
                return result;
            },
            providesTags: ['Embedding'],
            /*
               getAllEmbeddingSpaces: build.query({
            query: () => `/embedding_space/`,
            providesTags: ["Embedding"],
        }),
                 */

        }),


        getEmbeddingSpaceById: build.query({
            query: (es_id) => `/embedding_space/${es_id}`,
            providesTags: ["Embedding"],
        }),

        getEmbeddingSpaceExplorerData: build.query({
            query: (es_id) => `/embedding_space/${es_id}/explorer`,
//            providesTags: ["Embedding"],
        }),

        getEmbeddingSpaceExplorer2dData: build.query({
            query: (es_id) => `/embedding_space/${es_id}/explorer2d`,
//            providesTags: ["Embedding"],
        }),

        createEmbeddingSpace: build.mutation({
            query: (body) => {
                return ({
                    url: `/embedding_space/`,
                    method: 'POST',
                    body: body
                })
            }
        }),

        createProjection: build.mutation({
            query: (es_id) => {
                console.log("PROJECTION PAYLOAD:", es_id)
                return ({
                    url: `/embedding_space/${es_id}/es_projection`,
                    method: 'POST',
                    // body: body
                })
            }
        }),

        // This returns a job id.
        calcEmbeddingsDistance: build.mutation({
            query: (body) => {
                return ({
                    url: `/embedding_space/sim-matrix`,
                    method: 'POST',
                    body: body
            })},
            invalidatesTags: ["Job"],
        }),

        getEmbeddingValueHistogram: build.query({
            query: (es_id) => `/embedding_space/${es_id}/value-histogram`,
            providesTags: ["Histogram"],
        }),

        getEmbeddingSpaceDB: build.query({
            query: ({ es_id, db_id }) =>
                `/embedding_space/${es_id}/database/${db_id}`,
            providesTags: ["Database"],
        }),

        encodeRecords: build.mutation( {
            query: (body) => {
                console.log("ENCODE POST BODY:", body);
                return ({
                    url: `/embedding_space/fast-encode-records`,
                    method: 'POST',
                    body: body
                })
            }
        }),

        deleteEmbeddingSpace: build.mutation({
            query: ({project_id, es_id}) => ({
                url: `/project/${project_id}/embedding_spaces/${es_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Model", "Project", "Embedding"],
        }),

    }),
    // options
});

export const {
    // base getters
    useGetAllEmbeddingSpacesQuery,
    useGetEmbeddingSpaceByIdQuery,
    useGetEmbeddingDistanceByIdQuery,
    useGetEmbeddingValueHistogramQuery,
    useGetEmbeddingSpaceDBQuery,

    useGetEmbeddingSpaceExplorerDataQuery,
    useGetEmbeddingSpaceExplorer2dDataQuery,

    // mutations
    useDeleteEmbeddingSpaceMutation,
    useCalcEmbeddingsDistanceMutation,
    useEncodeRecordsMutation,
    useCreateEmbeddingSpaceMutation,
    useCreateProjectionMutation,

    // cool_and_fun_times
} = embeddingReducer;