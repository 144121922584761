
export function jobIsStalled(job) {
    const jobStartTime = new Date(job.last_updated);
    const timeNow = new Date();

    // console.log("jobStartTime = ", jobStartTime);
    const jobRunningSeconds = (timeNow - jobStartTime) / 1000.0;
    return jobRunningSeconds > 86400;
}

export const getRunningHaystackJob = (jobs) => {

    // console.log("getRunningHaystackJob = ", jobs);

    if (!jobs) {
        console.trace("getRunningHaystackJob: nothing passed in!")
        return null;
    }

    let haystackJob = null;
    // console.log("------------------------------------------------------------------------")
    for (var job_id of Object.keys(jobs)) {

        var job = jobs[job_id];
        if (jobIsStalled(job)) {
            console.log("job is stalled...", job)
            continue;
        }

        // console.log("GOT A JOB...", job, "; haystackJob = ", haystackJob);
        if (job.finished === true) {
            // not running
            continue;
        }

        const favoriteJobTypes = ["haystack-processing", "embedding-space-create", "model-create", "haystack-run-model"];
        if (favoriteJobTypes.includes(job.job_type)) { //} === "haystack-processing") {
            // console.log("HERE's A JOB!...", job);
            if (!job.start_time) {
                // ok it's not started...but was it created recently?  If so, consider it running.
                //if (job.job_type === "haystack-processing") {
                    // console.log("HERE's A JOB, never started--> ", job);
                    var nowTime = new Date();
                    var job_age = Math.abs(nowTime - new Date(job.created_at + "Z"));
                    // console.log("job_age = ", job._id, job_age);
                    if (job_age < (30 * 1000)) {
                        if (haystackJob === null) {
                            haystackJob = job;
                        }
                    }
                //}
                continue;
            }
            if (haystackJob !== null) {
                
                // console.log("checking...", haystackJob.created_at, job.created_at);
                if (job.created_at > haystackJob.created_at) {
                    // console.log("CHANGING HAYSTACK JOB TO: ", job);
                    if (job.start_time && !haystackJob.start_time) {
                        haystackJob = job;
                    }
                }
            } else {
                // console.log("SETTING HAYSTACK JOB TO: ", job);
                haystackJob = job;
            }
        }
    }

    return haystackJob;
}

export const hasRunningHaystackJob = (jobs) => {
    var result = getRunningHaystackJob(jobs);
    // console.log("RUNNING HAYSTACK RESULT --> ", result);
    return result !== null;
}


export const getRunningESTrainingJob = (jobs) => {

    // console.log("getRunningESTrainingJob = ", jobs);

    if (!jobs) {
        console.trace("getRunningESTrainingJob: nothing passed in!")
        return null;
    }

    let esJob = null;
    // console.log("------------------------------------------------------------------------")
    for (var job_id of Object.keys(jobs)) {

        var job = jobs[job_id];
        if (jobIsStalled(job)) {
            // console.log("job is stalled...", job)
            continue;
        }

        // console.log("GOT A JOB...", job, "; haystackJob = ", haystackJob);
        if (job.finished === true) {
            // not running
            continue;
        }

        const favoriteJobTypes = ["embedding-space-create"];
        if (favoriteJobTypes.includes(job.job_type)) {
            if (!job.start_time) {
                continue;
            }
            if (esJob !== null) {
                
                // console.log("checking...", haystackJob.created_at, job.created_at);
                if (job.created_at > esJob.created_at) {
                    // console.log("CHANGING HAYSTACK JOB TO: ", job);
                    esJob = job;
                }
            } else {
                // console.log("SETTING HAYSTACK JOB TO: ", job);
                esJob = job;
            }
        }
    }

    return esJob;
}
